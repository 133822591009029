import * as React from "react";
import PropTypes from "prop-types";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";

import {LIEN_INSTAGRAM, LIEN_FACEBOOK } from '../datas/vitrines';

import facebook from "../images/icn_facebook.svg";
import instagram from "../images/icn_instagram.svg";

const Header = ({ siteTitle }) => (
  <header className="px-3 sm:px-9 fixed w-full z-20 top-0 flex items-center justify-between">
    <Link to="/" className="titre_head txt-shadow text-white text-3xl m-0 no-underline" title="Les Vitrines de Valérie">LVDV</Link>
    <div>
      <AnchorLink className="txt-shadow inline-block align-middle text-white no-underline py-7 sm:mr-11 mr-4" to="/#tarifs" title="Tarifs">Tarifs</AnchorLink>
      
      <AnchorLink className="txt-shadow inline-block align-middle text-white no-underline py-7 sm:mr-11 mr-4" to="/#devis-contact" title="Devis / Contact">Devis / Contact</AnchorLink>
      
      <a href={LIEN_INSTAGRAM} target="_blank" rel="noreferrer" className="txt-shadow inline-block align-middle py-7 mr-2" ><img src={instagram} className="w-5 mb-0 txt-shadow" alt="Les Vitrines de Valérie sur Instagram" /></a>
      <a href={LIEN_FACEBOOK} target="_blank" rel="noreferrer" className="txt-shadow inline-block align-middle py-7" ><img src={facebook} className="w-4 mb-0 txt-shadow" alt='Les Vitrines de Valérie sur Facebook'  /></a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
