import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import logo_img from "../images/LVDV_logo.svg";

const NotFoundPage = () => (
  <Layout>
    <div className="bg-[url('../images/les-vitrines-de-valerie.jpg')] min-h-screen bg-cover bg-center flex items-center flex-col justify-center align-items-center relative">
      <div className="z-10 flex items-center flex-col justify-center align-items-center">
        <img src={logo_img} alt="Logo LVDV - Location de vitrines en France, en Belgique et au Luxembourg" />
        <h1 className="text-5xl md:text-7xl text-white my-12 text-center">Erreur 404</h1>
      </div>
      
      <div className="bottom-0 absolute md:-rotate-90 right-0 md:bottom-16 z-10">
        <p className="text-white/36 m-0">Photo vitrine</p>
        <p className="text-white m-0">Bouisset Bernard</p>
      </div>
      <div className="voile w-full h-full absolute pointer-events-none bg-black/50"></div>
    </div>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
